import { useWeb3 } from "react-dapp-web3";
import useGetRoles from "@hooks/backoffice/useGetRoles";

const useCheckIsMember = () => {
  const { walletAddress } = useWeb3();
  const { isWalletConnected } = useWeb3();
  const { dataResponse, loading } = useGetRoles(walletAddress);

  const isMember = isWalletConnected && !loading && dataResponse?.isMinimumValue;

  return { isMember };
};

export default useCheckIsMember;

import Title from "@components/Title";
import Button from "@components/Button";
import styles from "./VaultValuation.module.scss";
import { useWeb3 } from "react-dapp-web3";
import useGetReports from "@hooks/backoffice/useGetVaultReport";
import useGetRoles from "@hooks/backoffice/useGetRoles";
import { Variant } from "@components/Button/Button";

const VaultValuation = () => {
  const { isWalletConnected, connect, walletAddress } = useWeb3();  // Ensure you get walletAddress
  const vaultReport = useGetReports();
  const vaultReportData = vaultReport.data && vaultReport.data[0];
  
  const { dataResponse, loading } = useGetRoles(walletAddress);  // Pass wallet address to get balance

  // Debugging logs
  //console.log("Data Response:", dataResponse);
  //console.log("Loading Status:", loading);

  return (
    <>
      <Title 
        text={`VAULT VALUATION`} 
        tag="h3" 
        line 
      />
      {vaultReportData && (
        <div className={styles.anualReport}>
          <img
            src="https://i.postimg.cc/dQdrvCC5/Q1-2024-WHALE-NFT-Portfolio-Valuation-Quarter-1.png"
            alt="anual report banner"
          />
          <Button onClick={connect} variant={Variant.Primary}>
            {!isWalletConnected ? (
              "CONNECT WALLET"
            ) : loading ? (
              "Checking eligibility..."
            ) : dataResponse?.totalWhale < 1 ? (  // Check if user has less than 1 WHALE
              `WHALE Members only. You need to hold at least 1 $WHALE to access this report.`
            ) : (
              <a
                href={vaultReportData.reportUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                CHECK REPORT
              </a>
            )}
          </Button>
        </div>
      )}
    </>
  );
};

export default VaultValuation;

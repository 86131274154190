import { ReactElement, useState } from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import { TSlides } from "types/nftCollection";
import styles from "./VaultCarousel.module.scss";

interface IVaultCarousel {
  slides: TSlides[];
}

const VaultCarousel = ({ slides }: IVaultCarousel): ReactElement => {
  const table = slides.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) };
  });

  const [goToSlide, setGoToSlide] = useState<number>(0);
  const [cards] = useState(table);

  return (
    <div className={styles.container}>
      {slides && (
        <Carousel
          slides={cards}
          goToSlide={goToSlide}
          showNavigation={false}
          animationConfig={config.gentle}
        />
      )}
    </div>
  );
};

export default VaultCarousel;

import { ReactElement } from "react";
import styles from "./SimpleCarousel.module.scss";
import Carousel from "react-bootstrap/Carousel";
import WhaleTaleItem from "./components/WhaleTaleItem";
import "bootstrap/dist/css/bootstrap.min.css";
import { IArticle } from "@interfaces/backoffice/article";

interface ISimpleCarousel {
  articles: IArticle[];
  interval: number;
}

const SimpleCarousel = ({
  articles,
  interval,
}: ISimpleCarousel): ReactElement => (
  <div className={styles.container}>
    <Carousel indicators={false} interval={interval}>
      {articles.map(({ coverImage, title, uuid: id }) => (
        <Carousel.Item key={id}>
          <WhaleTaleItem title={title} image={coverImage!} id={id} />
        </Carousel.Item>
      ))}
    </Carousel>
  </div>
);

export default SimpleCarousel;

import { IArticle } from "@interfaces/backoffice/article";
import { unixTimestampToMDY } from "./DateUtils";
import Parser from "html-react-parser";

export const articleResponse = ({
  title,
  body,
  publishDate,
  category,
  documents,
}: IArticle) => {
  return {
    title: title,
    body: Parser(body),
    publishDate: unixTimestampToMDY(publishDate),
    category: category,
    documents: documents,
  };
};

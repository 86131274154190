import { ReactElement } from "react";
import styles from "./LatestOnWhaleCarousel.module.scss";
import Carousel from "react-bootstrap/Carousel";
import LatestTale from "./components/LatestTale";
import WeeklyCalendar from "@components/WeeklyCalendar";
import LatestVideo from "./components/LatestVideo";
import { ECarouselItemType } from "@constants/enums";
import { TLastestOn } from "types/WhaleLastestOn";
import "bootstrap/dist/css/bootstrap.min.css";

interface ILatestOnWhaleCarousel {
  posts: TLastestOn[];
  interval: number;
}

const LatestOnWhaleCarousel = ({
  posts,
  interval,
}: ILatestOnWhaleCarousel): ReactElement => (
  <div className={styles.container}>
    <Carousel indicators={false} interval={interval}>
      {posts.map((post) => {
        const { id, type, image } = post;
        return (
          <Carousel.Item key={id}>
            {type === ECarouselItemType.CALENDAR && (
              <WeeklyCalendar
                image={image}
                customStyles={styles.weeklyCalendar}
              />
            )}

            {type === ECarouselItemType.TALE && <LatestTale post={post} />}

            {type === ECarouselItemType.VIDEO && <LatestVideo post={post} />}
          </Carousel.Item>
        );
      })}
    </Carousel>
  </div>
);

export default LatestOnWhaleCarousel;

import { ReactElement, ReactNode } from "react";
import { Link, generatePath } from "react-router-dom";
import Masonry from "react-masonry-css";
import { ARTICLE_DETAIL, MEMBERS_ARTICLE_DETAIL } from "@constants/routes";
import styles from "./Articles.module.scss";
import defaultImage from "@assets/images/articles/article_image_default_min.png";
import { IArticle } from "@interfaces/backoffice/article";
import { unixTimestampToMDY } from "@scripts/DateUtils";

interface IArticles {
  articles: IArticle[];
  isPrivate: boolean;
}

const Articles = ({ articles, isPrivate }: IArticles): ReactElement => {
  const posts: ReactNode = articles?.map(
    ({ coverImage, title, uuid: id, publishDate }) => (
      <Link
        to={generatePath(isPrivate ? MEMBERS_ARTICLE_DETAIL : ARTICLE_DETAIL, {
          id,
        })}
        key={id}
        className={styles.postContainer}
      >
        <img
          src={coverImage ? coverImage : defaultImage}
          alt={`go to article ${id}`}
        />
        <p className={styles.date}>{unixTimestampToMDY(publishDate)}</p>
        <p>{title}</p>
      </Link>
    )
  );

  return (
    <div className={styles.container}>
      <Masonry
        breakpointCols={3}
        className={styles.grid}
        columnClassName={styles.gridColumnDesktop}
      >
        {posts}
      </Masonry>

      <Masonry
        breakpointCols={2}
        className={styles.grid}
        columnClassName={styles.gridColumnLaptop}
      >
        {posts}
      </Masonry>

      <Masonry
        breakpointCols={1}
        className={styles.grid}
        columnClassName={styles.gridColumnMobile}
      >
        {posts}
      </Masonry>
    </div>
  );
};

export default Articles;

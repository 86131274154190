import { ReactElement } from "react";
import useGetPartners from "@hooks/backoffice/useGetPartners";
import Loader from "@components/Loader";
import styles from "./WhalePartners.module.scss";
import PartnersSection from "./components/PartnersSection";

const WhalePartners = (): ReactElement => {
  const { data: partnersResponse, loading } = useGetPartners();

  if (
    loading &&
    !partnersResponse?.chiefs &&
    !partnersResponse?.investors &&
    !partnersResponse?.team
  ) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      {partnersResponse && partnersResponse.team && (
        <PartnersSection
          list={partnersResponse.team}
          title={"WHALE LEADERSHIP"}
        />
      )}

      {partnersResponse && partnersResponse.chiefs && (
        <PartnersSection
          list={partnersResponse.chiefs}
          title={"CHIEF PARTNERS"}
        />
      )}

      {partnersResponse && partnersResponse.investors && (
        <PartnersSection
          list={partnersResponse.investors}
          title={"Private token sales Investors"}
        />
      )}
    </div>
  );
};

export default WhalePartners;

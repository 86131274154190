import { ReactElement } from "react";
import styles from "./Title.module.scss";

interface ITitle {
  text: string;
  fixedWidth?: boolean;
  tag: "h1" | "h2" | "h3";
  line?: boolean;
  center?: boolean;
}

const Title = ({
  text,
  fixedWidth,
  tag,
  line,
  center,
}: ITitle): ReactElement => {
  const tags = {
    h1: <h1>{text}</h1>,
    h2: <h2>{text}</h2>,
    h3: <h3>{text}</h3>,
  };

  return (
    <div
      className={`${styles.container} ${fixedWidth && styles.fixedWidth} ${
        center && styles.center
      }`}
    >
      {tags[tag]}
      {line && (
        <div className={styles.lineContainer}>
          <hr />
        </div>
      )}
    </div>
  );
};

export default Title;

import { TLastestOn } from "types/WhaleLastestOn";
import { ECarouselItemType } from "@constants/enums";
import { ICalendar } from "@interfaces/backoffice/calendar";
import { IArticle } from "@interfaces/backoffice/article";
import defaultImage from "@assets/images/articles/article_image_default.png";
import { format } from "date-fns";

export const articleResponseToLatestOn = (article: IArticle): TLastestOn => {
  return {
    image: article.coverImage ? article.coverImage : defaultImage,
    title: article.title,
    date: format(article.publishDate, "MMMM do, yyyy"),
    id: article.uuid,
    type: ECarouselItemType.TALE,
  };
};

export const calendarReponseToLatestOn = (calendar: ICalendar): TLastestOn => {
  return {
    id: calendar.uuid,
    title: calendar.title,
    type: ECarouselItemType.CALENDAR,
    image: calendar.linkImage,
  };
};

import { ReactElement } from "react";
import { SymbolOverview } from "react-ts-tradingview-widgets";
import styles from "./CryptoChart.module.scss";

interface ICryptoChart {
  symbols: string[][];
  lineColor: string;
}

const CryptoChart = ({ symbols, lineColor }: ICryptoChart): ReactElement => {
  return (
    <div className={styles.container}>
      <SymbolOverview
        container_id="advanced-chart-widget-container"
        autosize
        symbols={symbols}
        lineColor={lineColor}
        topColor={lineColor}
        bottomColor={"#ffffff00"}
        dateFormat={"dd MMM 'yy"}
      />
    </div>
  );
};

export default CryptoChart;

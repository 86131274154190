import { ReactElement } from "react";
import { generatePath } from "react-router-dom";
import { ARTICLE_DETAIL } from "@constants/routes";
import styles from "./WhaleTaleItem.module.scss";
import defaultImage from "@assets/images/articles/article_image_default.png";

interface IWhaleTaleItem {
  image?: string;
  title: string;
  id: string;
}

const WhaleTaleItem = ({ title, image, id }: IWhaleTaleItem): ReactElement => (
  <div className={styles.container}>
    <div className={styles.imageContainer}>
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${image ? image : defaultImage})` }}
        data-testid="image"
      />
    </div>

    <div className={styles.postDescription}>
      <div>
        <h4>{title}</h4>
        <div>
          <a
            href={generatePath(ARTICLE_DETAIL, { id })}
            rel="noopener noreferrer"
          >
            Read More
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default WhaleTaleItem;

import Title from "@components/Title";
import { ReactElement, useState, useEffect } from "react";
import styles from "./VaultHighlights.module.scss";
import VaultCarousel from "../VaultCarousel";
import { OPENSEA_VAULT_URI } from "@constants/config";
import button_styles from "@components/Button/Button.module.scss";
import { TSlides } from "types/nftCollection";
import Loader from "@components/Loader";
import useGetCarousel from "@hooks/backoffice/useGetVaultCarousel";
import { createSlides } from "@scripts/SlidesUtils";

const VaultHighlights = (): ReactElement => {
  const [nftCollection, setNftCollection] = useState<TSlides[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const vaultCarousel = useGetCarousel();

  useEffect(() => {
    const slides = createSlides(vaultCarousel.data!);
    slides && setNftCollection(slides);
    setIsLoading(false);
  }, [vaultCarousel.data]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className={styles.goToTheVault}>
        <Title text={"WHALE VAULT HIGHLIGHTS"} tag="h3" line />
        <a
          href={OPENSEA_VAULT_URI}
          target="_blank"
          rel="noopener noreferrer"
          className={button_styles.secondary}
        >
          GO TO THE VAULT
        </a>
      </div>
      {nftCollection && <VaultCarousel slides={nftCollection} />}
    </>
  );
};

export default VaultHighlights;

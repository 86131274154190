import { ECarouselItemType } from "@constants/enums";
import { IVideo } from "@interfaces/socialMedia/youtubeResponse";
import { TVideos } from "types/whaleFmPosts";
import { TLastestOn } from "types/WhaleLastestOn";

export const youtubeResponseToVideo = (video: IVideo): TVideos => {
  const idVideo = video.id.videoId ?? video.snippet.resourceId.videoId;

  return {
    id: idVideo,
    title: video.snippet.title,
    videoUrl: "https://www.youtube.com/embed/" + idVideo,
    description: video.snippet.description,
  };
};

export const youtubeResponseToLastestOn = (video: IVideo): TLastestOn => {
  const idVideo = video.id.videoId ?? video.snippet.resourceId.videoId;

  return {
    id: idVideo,
    title: video.snippet.title,
    videoUrl: "https://www.youtube.com/embed/" + idVideo,
    image: "",
    type: ECarouselItemType.VIDEO,
  };
};

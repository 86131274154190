import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faTimes,
  faXmark,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faLinkedin,
  faFacebook,
  faTwitter,
  faLinkedinIn,
  faDiscord,
  faYoutube,
  faTwitch,
  faMedium,
  faTelegram,
  faRedditAlien,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faTelegram,
  faRedditAlien,
  faCommentDots,
  faMedium,
  faTwitch,
  faYoutube,
  faDiscord,
  faXmark,
  faTimes,
  faBars,
  faFacebookSquare,
  faFacebook,
  faLinkedin,
  faLinkedinIn,
  faTwitter
);

import { API_URL, API_URL_BACKEND_FGINDEX } from "@constants/backend.env";
import { useAxios } from "use-axios-client";
import { IFearGreed } from "@interfaces/backoffice/fearGreed";

const useGetFearGreed = () =>
  useAxios<IFearGreed>({
    baseURL: API_URL,
    url: API_URL_BACKEND_FGINDEX,
  });

export default useGetFearGreed;

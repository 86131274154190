import { ReactElement } from "react";
import whaleLogo from "@assets/images/utils/whale-logo.png";
import styles from "./Loader.module.scss";

const Loader = (): ReactElement => (
  <div className={styles.container}>
    <div className={styles.loader}>
      <img src={whaleLogo} alt="whale loading..." />
      <div className={styles.spinner} />
    </div>
  </div>
);

export default Loader;

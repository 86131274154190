import { ReactElement } from "react";
import Title from "@components/Title";
import WhaleTaleHighlightsCarousel from "./components/WhaleTaleHighlightsCarousel";
import DiscoverMoreTales from "./components/DiscoverMoreTales";
import { Link, generatePath } from "react-router-dom";
import { ARTICLE_DETAIL } from "@constants/routes";
import styles from "./WhaleTalesScreen.module.scss";
import Loader from "@components/Loader";
import { EArticleCategories, EArticleTypes } from "@constants/enums";
import useGetArticles from "@hooks/backoffice/useGetArticles";
import useGetLatestArticles from "@hooks/backoffice/useGetLatestArticles";

const WhaleTalesScreen = (): ReactElement => {
  const {
    articlesToShow: generalArticlesToShow,
    isLoading: generalIsLoading,
    handleGetArticles,
    hasMore,
  } = useGetArticles(EArticleCategories.PUBLIC, EArticleTypes.GENERAL);
  const { articlesToShow: highlightArticlesToShow } = useGetArticles(
    EArticleCategories.PUBLIC,
    EArticleTypes.HIGHLIGHT,
    6
  );
  const { data: lastarticleResponse, loading } = useGetLatestArticles();

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      {highlightArticlesToShow && (
        <>
          <Title fixedWidth text={"Whale Tales Highlights"} tag="h3" line />
          <WhaleTaleHighlightsCarousel highlights={highlightArticlesToShow} />
        </>
      )}

      <Title text={"WHALE TALES"} tag="h3" line />
      {lastarticleResponse && (
        <>
          <Link
            className={styles.postPreview}
            to={generatePath(ARTICLE_DETAIL, { id: lastarticleResponse[0].id })}
          >
            <h5>{lastarticleResponse[0].title}</h5>
            <img
              src={lastarticleResponse[0].image}
              alt={`go to post ${lastarticleResponse[0].title}`}
            />
          </Link>
        </>
      )}

      {generalArticlesToShow && (
        <DiscoverMoreTales
          tales={generalArticlesToShow}
          isLoading={generalIsLoading}
          handleGetArticles={handleGetArticles}
          hasMore={false}
        />
      )}
    </div>
  );
};

export default WhaleTalesScreen;

import { API_URL } from "@constants/backend.env";
import { API_URL_BACKEND_WALLET } from "@constants/backend.env";
import { useEffect, useState } from "react";
import axios from "axios";
import Web3 from "web3";

const useGetRoles = (walletAddress: string) => {
  const [dataResponse, setDataResponse] = useState({
    totalWhale: 0,
    whaleInEther: 0,
    whaleInPolygon: 0,
    isMinimumValue: true,
    role: { name: "string", value: 0 }
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkTokenBalances = async () => {
      try {
        // Make the request to the PHP backend for the WHALE balance
        const response = await axios.post(API_URL+API_URL_BACKEND_WALLET, {
          walletAddress: walletAddress
        });

        // Update the state with the balance
        setDataResponse({
          totalWhale: parseFloat(response.data.totalWhale.toFixed(4)),
          whaleInEther: parseFloat(response.data.ethWhaleBalance.toFixed(4)),
          whaleInPolygon: parseFloat(response.data.polyWhaleBalance.toFixed(4)),
          isMinimumValue: response.data.totalWhale >= 1,  // Check if total is greater than or equal to 1
          role: { name: "member", value: response.data.totalWhale }
        });
      } catch (error) {
        console.error("Error fetching balance:", error);
      } finally {
        setLoading(false);
      }
    };

    if (walletAddress) {
      checkTokenBalances();
    }
  }, [walletAddress]);

  return { dataResponse, loading };
};

export default useGetRoles;
import { ReactElement } from "react";
import { TLastestOn } from "types/WhaleLastestOn";
import styles from "./LatestVideo.module.scss";

interface ILatestVideo {
  post: TLastestOn;
}

const LatestVideo = ({ post }: ILatestVideo): ReactElement => (
  <iframe
    className={styles.iframe}
    key={post.id}
    title={post.title}
    src={post.videoUrl}
    frameBorder="0"
    allow="autoplay; encrypted-media"
    allowFullScreen
  />
);

export default LatestVideo;

import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useWeb3 } from "react-dapp-web3";
import { shortenWalletAddress } from "@scripts/Strings";
import { HOME } from "@constants/routes";
import { Variant } from "@components/Button/Button";
import Button from "@components/Button";
import Navbar from "@components/Navbar";
import whale_logo from "@assets/images/utils/whale-brand-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./HeaderMobile.module.scss";

const HeaderMobile = (): JSX.Element => {
  const { pathname } = useLocation();
  const [toggle, setToggle] = useState(false);
  const { isWalletConnected, walletAddress, connect } = useWeb3();

  useEffect(() => {
    setToggle(false);
  }, [pathname]);

  const handleConnect = () => {
    if (isWalletConnected) {
      return;
    }
    connect();
  };

  return (
    <header className={styles.container}>
      <div className={styles.buttons}>
        <div className={styles.main}>
          <button onClick={() => setToggle(!toggle)}>
            {toggle ? (
              <FontAwesomeIcon
                icon={["fas", "xmark"]}
                color="white"
                size="2x"
              />
            ) : (
              <FontAwesomeIcon icon={["fas", "bars"]} color="white" size="2x" />
            )}
          </button>

          <Link to={HOME}>
            <img
              className={styles.brandLogo}
              src={whale_logo}
              alt="whale logo"
            />
          </Link>
        </div>

        <Button onClick={handleConnect} variant={Variant.Primary}>
          {isWalletConnected
            ? shortenWalletAddress(walletAddress)
            : "CONNECT WALLET"}
        </Button>
      </div>
      {toggle && <Navbar />}
    </header>
  );
};

export default HeaderMobile;

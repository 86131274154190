import { ReactElement } from "react";
import styles from "./NFTFearGreedIndex.module.scss";

const NFTFearGreedIndex = ({ image }: { image: string }): ReactElement => (
  <div className={styles.container}>
    <img
      className={styles.indexImage}
      src={image}
      alt="NFT Fear and Greed index"
    />
  </div>
);

export default NFTFearGreedIndex;

import { ReactElement } from "react";
import Title from "@components/Title";
import SimpleCarousel from "@components/SimpleCarousel";
import styles from "./WhaleTales.module.scss";
import Loader from "@components/Loader";
import useGetArticles from "@hooks/backoffice/useGetArticles";
import { EArticleCategories, EArticleTypes } from "@constants/enums";

const WhaleTales = (): ReactElement => {
  const {
    articlesToShow: highlightArticlesToShow,
    isLoading: highlightIsLoading,
  } = useGetArticles(EArticleCategories.PUBLIC, EArticleTypes.HIGHLIGHT, 3);

  if (highlightIsLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      {highlightArticlesToShow && (
        <>
          <Title text={"WHALE TALES"} tag="h3" line />
          <SimpleCarousel articles={highlightArticlesToShow} interval={5000} />
        </>
      )}
    </div>
  );
};

export default WhaleTales;

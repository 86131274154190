import { ReactElement } from "react";
import styles from "./WhaleFmTwitch.module.scss";
import ReactPlayer from "react-player/twitch";
import { TWITCH } from "@constants/socialMedia.links";

const WhaleFmTwitch = (): ReactElement => (
  <div className={styles.container} data-testid="twitch-player">
    <ReactPlayer playing={true} controls={true} url={TWITCH} />
  </div>
);

export default WhaleFmTwitch;

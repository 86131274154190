import { ReactElement } from "react";
import Title from "@components/Title";
import styles from "./DiscoverMoreTales.module.scss";
import { IArticle } from "@interfaces/backoffice/article";
import Scroll from "@components/Scroll/Scroll";

interface IDiscoverMoreTales {
  tales: IArticle[];
  isLoading: boolean;
  handleGetArticles: () => {};
  hasMore: boolean;
}

const DiscoverMoreTales = ({
  tales,
  isLoading,
  handleGetArticles,
  hasMore,
}: IDiscoverMoreTales): ReactElement => (
  <div className={styles.container}>
    <Title text={"DISCOVER MORE TALES"} tag="h3" center line />
    <Scroll
      articlesToShow={tales}
      isLoading={isLoading}
      handleGetArticles={handleGetArticles}
      hasMore={hasMore}
      isPrivate={false}
    />
  </div>
);

export default DiscoverMoreTales;

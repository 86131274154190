import { ReactElement, useEffect, useState } from "react";
import Parser from "html-react-parser";
import Title from "@components/Title";
import useGetHighlight from "@hooks/backoffice/useGetVaultHighlights";
import styles from "./HighlightsVault.module.scss";
import IHotv from "@interfaces/backoffice/hotv";  // Import your IHotv interface

const HighlightsVault = (): ReactElement => {
  // Update useState to accept both null and IHotv type
  const [randomArtVault, setRandomArtVault] = useState<IHotv | null>(null);

  const { data } = useGetHighlight();

  useEffect(() => {
    if (data && data.length > 0) {
      // Randomly select an art piece from the fetched data
      const randomIndex = Math.floor(Math.random() * data.length);
      setRandomArtVault(data[randomIndex]);
    }
  }, [data]);

  return (
    <div className={styles.container}>
      {randomArtVault && (
        <>
          <Title text="Highlights of the vault" tag="h3" line fixedWidth />
          <div className={styles.vault}>
            <div className={styles.artInformation}>
              <h5>{randomArtVault.title}</h5>
              <p className={styles.sign}>{randomArtVault.collectionName}</p>
              <p className={styles.note}>{Parser(randomArtVault.description)}</p>
              <hr />
              <p className={styles.link}>
                <a
                  href={randomArtVault.openSeaUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Discover
                </a>
              </p>
            </div>
            <div className={styles.imageContainer}>
              <img src={randomArtVault.imageUrl} alt="highlights vault" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HighlightsVault;

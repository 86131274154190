import { ReactElement, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Loader from "../Loader";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import styles from "./DocumentEmbed.module.scss";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

interface IDocumentEmbed {
  document: Document;
}

const DocumentEmbed = ({ document }: IDocumentEmbed): ReactElement => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  let documentScale = 1.5;

  const nextPage = () => {
    pageNumber !== numPages && setPageNumber(pageNumber + 1);
  };

  const pastPage = () => {
    pageNumber > 1 && setPageNumber(pageNumber - 1);
  };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  if (useMediaQuery({ query: "(max-width: 992px)" })) {
    documentScale = 1.2;
  }

  if (useMediaQuery({ query: "(max-width: 768px)" })) {
    documentScale = 0.9;
  }

  if (useMediaQuery({ query: "(max-width: 480px)" })) {
    documentScale = 0.7;
  }

  return (
    <div className={styles.container}>
      <Document
        file={document}
        onLoadSuccess={onDocumentLoadSuccess}
        externalLinkTarget="_blank"
      >
        <Page pageNumber={pageNumber} scale={documentScale} />
      </Document>
      {numPages ? (
        <div className={styles.controls}>
          <button
            onClick={pastPage}
            className={pageNumber === 1 ? styles.disabled : ""}
          >
            {"<"}
          </button>
          <p>
            Page {pageNumber} of {numPages}
          </p>

          <button
            onClick={nextPage}
            className={numPages === pageNumber ? styles.disabled : ""}
          >
            {">"}
          </button>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};
export default DocumentEmbed;

import { ReactElement, HTMLAttributes } from "react";
import Slider from "react-slick";
import { Link, generatePath } from "react-router-dom";
import { ARTICLE_DETAIL } from "@constants/routes";
import styles from "./WhaleTaleHighlightsCarousel.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IArticle } from "@interfaces/backoffice/article";
import defaultImage from "@assets/images/articles/article_image_default.png";

interface IWhaleTaleHighlightsCarousel {
  highlights: IArticle[];
}

const WhaleTaleHighlightsCarousel = ({
  highlights,
}: IWhaleTaleHighlightsCarousel): ReactElement => {
  function NextArrow(props: HTMLAttributes<HTMLButtonElement>) {
    const { className, onClick } = props;
    return (
      <button
        className={`${className} ${styles.arrow} ${styles.rightArrow}`}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props: HTMLAttributes<HTMLButtonElement>) {
    const { className, onClick } = props;
    return (
      <button
        className={`${className} ${styles.arrow} ${styles.leftArrow}`}
        onClick={onClick}
      />
    );
  }

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: highlights.length < 2 ? 1 : 2,
    slidesToScroll: 2,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.container}>
      <Slider {...settings}>
        {highlights.map(({ coverImage, title, uuid: id }) => (
          <Link
            to={generatePath(ARTICLE_DETAIL, { id })}
            key={id}
            className={styles.postContainer}
          >
            <div className={styles.imageContainer}>
              <img
                src={coverImage ? coverImage : defaultImage}
                alt={`go to post ${id}`}
              />
            </div>

            <p>{title}</p>
          </Link>
        ))}
      </Slider>
    </div>
  );
};

export default WhaleTaleHighlightsCarousel;

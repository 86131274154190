import { API_URL, API_URL_BACKEND_GLANCES } from "@constants/backend.env";
import { useAxios } from "use-axios-client";
import { IGlance } from "@interfaces/backoffice/glance";

const useGetGlances = () =>
  useAxios<IGlance[]>({
    baseURL: API_URL,
    url: API_URL_BACKEND_GLANCES,
    params: {
      state: true,
      order: "asc",
      sort: "order",
    },
  });

export default useGetGlances;

import { API_URL } from "@constants/backend.env";
import { API_URL_BACKEND_ARTICLES } from "@constants/backend.env";
import { IArticle } from "@interfaces/backoffice/article";
import { EArticleCategories } from "@constants/enums";
import { TLastestOn } from "types/WhaleLastestOn";
import { articleResponseToLatestOn } from "@scripts/TalesUtils";
import { useAxios } from "use-axios-client";

const useGetLatestArticles = () =>
  useAxios<TLastestOn[]>({
    url: `${API_URL}${API_URL_BACKEND_ARTICLES}`,
    params: {
      category: EArticleCategories.PUBLIC,
      sort: "publishDate",
      direction: "desc",
      state: true,
      limit: 2,
    },
    transformResponse: [
      (data) => {
        let posts: TLastestOn[] = [];

        try {
          let resp = JSON.parse(data);

          resp?.map((article: IArticle) =>
            posts.push(articleResponseToLatestOn(article))
          );
        } catch (error) {
          console.log(error);
        }

        return posts;
      },
    ],
  });

export default useGetLatestArticles;

import { ReactElement } from "react";
import styles from "./WeeklyCalendar.module.scss";

interface IWeeklyCalendar {
  image: string;
  customStyles?: string;
}

const WeeklyCalendar = ({
  image,
  customStyles,
}: IWeeklyCalendar): ReactElement => (
  <div className={customStyles ?? styles.container}>
    <img src={image} alt="calendar pic" />
  </div>
);

export default WeeklyCalendar;

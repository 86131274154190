import { ReactElement, ReactNode, useState } from "react";
import Loader from "../Loader";
import styles from "./EntryLoader.module.scss";

const EntryLoader = ({ children }: { children: ReactNode }): ReactElement => {
  const [hasLoaded, setHasLoaded] = useState(false);

  const timer = setTimeout(() => {
    setHasLoaded(true);
  }, 1000);

  hasLoaded && clearTimeout(timer);

  return (
    <div className={styles.container}>
      <div
        className={`${styles.loader} ${hasLoaded ? styles.hide : styles.show}`}
      >
        <Loader />
      </div>

      <div>{children}</div>
    </div>
  );
};

export default EntryLoader;

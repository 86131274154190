import { ReactElement } from "react";
import styles from "./VaultCarouselContent.module.scss";

export interface IVaultCarouselContent {
  img: {
    imageUrl: string;
    title: string;
    openSeaUrl: string;
  };
}

const VaultCarouselContent = ({ img }: IVaultCarouselContent): ReactElement => (
  <div className={styles.containerPiece}>
    <div className={styles.imageContainer}>
      <img
        className={styles.artPiece}
        draggable={false}
        src={img.imageUrl}
        alt={`nft ${img.title}`}
      />
    </div>

    <a
      href={img.openSeaUrl}
      className={styles.linkPiece}
      rel="noreferrer noopener"
      target="_blank"
    >
      View on OpenSea
    </a>
  </div>
);

export default VaultCarouselContent;

import { ReactElement } from "react";
import Title from "@components/Title";
import DocumentEmbed from "@components/DocumentEmbed";
import document from "@assets/documents/whitepaper.pdf";
import styles from "./WhaleWhitepaper.module.scss";

const WhaleWhitepaper = (): ReactElement => (
  <div className={styles.container}>
    <Title text={"WHALE-PAPER"} tag="h3" fixedWidth line />
    <DocumentEmbed document={document} />
  </div>
);

export default WhaleWhitepaper;

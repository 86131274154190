import { format } from "date-fns";

const MS_MULTIPLIER = 1000;

export const unixTimestampToMDY = (date: number) => {
  //const dateInMilliseconds = date * MS_MULTIPLIER;
  const dateObject = new Date(date); // Create Date object

  return format(dateObject, "MMMM do, yyyy"); // Format the Date object
};


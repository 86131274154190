import { API_URL } from "@constants/backend.env";
import { useAxios } from "use-axios-client";
import { API_URL_BACKEND_CALENDAR } from "@constants/backend.env";
import { ICalendar } from "@interfaces/backoffice/calendar";
import { calendarReponseToLatestOn } from "@scripts/TalesUtils";
import { TLastestOn } from "types/WhaleLastestOn";

const useGetCalendar = () =>
  useAxios<TLastestOn | null>({
    baseURL: API_URL,
    url: API_URL_BACKEND_CALENDAR,
    params: {
      state: true,
      order: "desc",
      sort: "createdAt",
    },
    transformResponse: [
      (data) => {
        try {
          let calendars: ICalendar[] = JSON.parse(data);

          // Check if calendars array is empty and provide a fallback
          if (!calendars || calendars.length === 0) {
            return null; // or return a default value
          }

          return calendarReponseToLatestOn(calendars[0]);
        } catch (error) {
          console.log(error);
          return null; // Return null if JSON parsing fails
        }
      },
    ],
  });

export default useGetCalendar;


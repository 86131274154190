import { IVaultCarousel } from "@interfaces/backoffice/carousel";
import VaultCarouselContent from "../../pages/WhaleVaultScreen/components/VaultCarouselContent";

export const createSlides = (data: IVaultCarousel[]) => {
  const slides = data?.map((img) => ({
    key: img.uuid,
    content: <VaultCarouselContent img={img} />,
  }));

  return slides;
};

import { API_URL } from "@constants/backend.env";
import { useAxios } from "use-axios-client";
import { API_URL_BACKEND_REPORT } from "@constants/backend.env";
import { IVaultReport } from "@interfaces/backoffice/report";

const useGetReports = () =>
  useAxios<IVaultReport[]>({
    baseURL: API_URL,
    url: API_URL_BACKEND_REPORT,
    params: {
      state: true,
      order: "desc",
      sort: "createdAt",
    },
  });

export default useGetReports;

import { ReactElement } from "react";
import LatestOnWhale from "./components/LatestOnWhale";
import HighlightsVault from "./components/HighlightsVault";
import WhaleAtAGlance from "./components/WhaleAtAGlance";
import WhaleTales from "./components/WhaleTales";
import WhaleFmVideo from "./components/WhaleFmVideo";
import WhaleBenefits from "./components/WhaleBenefits";
import styles from "./Home.module.scss";

const Home = (): ReactElement => (
  <div className={styles.container}>
    <p className={styles.introText}>
      <span>ART X CULTURE X NFTs: </span> WHALE is the Omniversal membership
      club for the Natively Digital.
    </p>
    <LatestOnWhale />
    <HighlightsVault />
    <WhaleAtAGlance />
    <WhaleBenefits />
    <WhaleTales />
    <WhaleFmVideo />
  </div>
);

export default Home;

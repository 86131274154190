import { ReactElement } from "react";
import styles from "./WhaleVaultScreen.module.scss";
import VaultHighlights from "./components/VaultHighlights";
import VaultValuation from "./components/VaultValuation";

const WhaleVaultScreen = (): ReactElement => {
  return (
    <div className={styles.container}>
      <VaultHighlights />
      <VaultValuation />
    </div>
  );
};

export default WhaleVaultScreen;

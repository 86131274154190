import { ReactElement } from "react";

const Error404 = (): ReactElement => (
  <div>
    <h1>Error 404</h1>
    <p>Page not found.</p>
  </div>
);

export default Error404;

import { ReactElement } from "react";
import NFTFearGreedIndex from "./components/NFTFearGreedIndex";
import UniswapExchange from "./components/UniswapExchange";
import useGetFearGreed from "@hooks/backoffice/useGetFearGreed";
import whaleBanner from "@assets/images/utils/whale-banner.png";
import styles from "./WhaleMembers.module.scss";
import useGetRoles from "@hooks/backoffice/useGetRoles";
import useCheckIsMember from "@hooks/backoffice/useCheckIsMember";
import Error403 from "@pages/Error403";
import MembersArticles from "./components/MembersArticles";
import { useWeb3 } from "react-dapp-web3";

const WhaleMembers = (): ReactElement => {
  const { isMember } = useCheckIsMember();
  const { walletAddress } = useWeb3();
  const { dataResponse, loading } = useGetRoles(walletAddress);
  const { data } = useGetFearGreed();
  const whaleIndex = data?.imageUrl;

  if (!loading && !isMember) {
    return <Error403 />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <img src={whaleBanner} alt="background of title" />
        <h2>WHALE MEMBERS</h2>
      </div>
      <div className={styles.content}>
        <div className={styles.userData}>
          {!loading && dataResponse && (
            <div className={styles.accountBalance}>
              <h4>Account balance : {dataResponse.totalWhale} WHALE</h4>
              <h4>ETH-Chain balance : {dataResponse.whaleInEther} WHALE</h4>
              <h4>POLY-CHAIN balance : {dataResponse.whaleInPolygon} WHALE</h4>
            </div>
          )}
          {whaleIndex && <NFTFearGreedIndex image={whaleIndex} />}
        </div>
        <div className={styles.exchangeContainer}>
          <UniswapExchange />
        </div>
      </div>
      <MembersArticles />
    </div>
  );
};

export default WhaleMembers;

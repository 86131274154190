import { API_URL } from "@constants/backend.env";
import { API_URL_BACKEND_ARTICLES } from "@constants/backend.env";
import { IArticle } from "@interfaces/backoffice/article";
import axios from "axios";
import { useState, useEffect } from "react";
import {
  ARTICLE_LIMIT,
  ARTICLE_OFFSET,
  INITIAL_ARTICLE_LIMIT,
  INITIAL_ARTICLE_OFFSET,
} from "@constants/articles";

const useGetArticles = (
  category: string,
  type?: string,
  requestLimit?: number
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [articlesToShow, setArticlesToShow] = useState<IArticle[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(INITIAL_ARTICLE_LIMIT);
  const [offset, setOffset] = useState(INITIAL_ARTICLE_OFFSET);

  useEffect(() => {
    handleGetArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getArticles = async (): Promise<IArticle[]> => {
    setIsLoading(true);
    let articles: IArticle[] = [];

    await axios
      .get(`${API_URL}${API_URL_BACKEND_ARTICLES}`, {
        params: {
          limit: requestLimit ? requestLimit : limit,
          offset: page * offset,
          sort: "publishDate",
          direction: "desc",
          state: true,
          category,
          type,
        },
      })
      .then(({ data }) => {
        articles = data;
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    return articles;
  };

  const handleGetArticles = async () => {
    const articlesList = await getArticles();

    setHasMore(
      articlesList.length !== 0 && articlesList.length >= ARTICLE_LIMIT
    );
    setLimit(ARTICLE_LIMIT);
    setOffset(ARTICLE_OFFSET);
    setArticlesToShow(articlesToShow.concat(articlesList));
    setPage((page) => page + 1);
  };

  return {
    isLoading,
    articlesToShow,
    handleGetArticles,
    hasMore,
  };
};

export default useGetArticles;

export enum ECarouselItemType {
  TALE = "tale",
  VIDEO = "video",
  CALENDAR = "calendar",
}

export enum EArticleCategories {
  PUBLIC = "Public",
  MEMBERS = "Members",
}

export enum EArticleTypes {
  HIGHLIGHT = "highlight",
  GENERAL = "general",
}

import { ReactElement } from "react";
import { WHALE_CONTRACT_ADDRESS } from "@constants/env";
import styles from "./UniswapExchange.module.scss";

const UniswapExchange = (): ReactElement => (
  <iframe
    title="uniswap"
    src={`https://app.uniswap.org/#/swap?theme=dark&outputCurrency=${WHALE_CONTRACT_ADDRESS}`}
    className={styles.container}
    name="uniswap exchange"
  />
);

export default UniswapExchange;

import { ReactElement } from "react";
import useGetBenefits from "@hooks/backoffice/useGetBenefits";
import Loader from "@components/Loader";
import Title from "@components/Title";
import styles from "./WhaleBenefits.module.scss";

const WhaleBenefits = (): ReactElement => {
  const { data, loading } = useGetBenefits();

  if (loading || !data) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <Title text={"WHALE BENEFITS"} tag="h3" line />
      <div className={styles.gridContainer}>
        <div className={styles.benefitsContainer}>
          {data?.map(({ title, description }) => (
            <div className={styles.benefit} key={title}>
              <div className={styles.textContainer}>
                <p className={styles.benefitTitle}>{title}</p>
                <p className={styles.benefitDescription}>{description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhaleBenefits;

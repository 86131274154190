import { ReactElement } from "react";

const Error403 = (): ReactElement => (
  <div>
    <h1>Error 403</h1>
    <p>Access allowed only to Whale Members.</p>
  </div>
);

export default Error403;

import { ReactElement } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import EntryLoader from "../EntryLoader";
import HeaderMobile from "./components/HeaderMobile";
import Error404 from "@pages/Error404";
import Home from "@pages/Home";
import WhaleTalesScreen from "@pages/WhaleTalesScreen";
import WhaleFmScreen from "@pages/WhaleFmScreen";
import Header from "./components/Header";
import Container from "../Container";
import Footer from "./components/Footer";
import WhaleVaultScreen from "@pages/WhaleVaultScreen";
import PrivacyPolicy from "@pages/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop";
import WhaleMembers from "@pages/WhaleMembers";
import WhalePartners from "@pages/WhalePartners";
import ArticleDetail from "@pages/ArticleDetail";
import WhaleWhitepaper from "@pages/WhaleWhitepaper";
import TermsAndConditions from "@pages/TermsAndConditions";
import PrivateRoute from "../PrivateRoute";
import {
  HOME,
  WHALE_TALES,
  WHALE_DAO,
  WHALE_FM,
  WHALE_MEMBERS,
  WHALE_VAULT,
  PRIVACY_POLICY,
  WHALE_PARTNERS,
  TERMS_AND_CONDITIONS,
  ARTICLE_DETAIL,
  MEMBERS_ARTICLE_DETAIL,
  PREVIEW_ARTICLE_DETAIL,
} from "@constants/routes";
import Error403 from "@pages/Error403";

const App = (): ReactElement => (
  <EntryLoader>
    <Router>
      <Header />
      <HeaderMobile />
      <Container>
        <ScrollToTop>
          <Switch>
            <Route path={HOME} component={Home} exact />
            <Route path={WHALE_TALES} component={WhaleTalesScreen} exact />
            <Route path={WHALE_DAO} component={WhaleWhitepaper} exact />
            <Route path={WHALE_FM} component={WhaleFmScreen} exact />
            <PrivateRoute path={WHALE_MEMBERS} component={WhaleMembers} exact />
            <Route path={ARTICLE_DETAIL} component={ArticleDetail} exact />
            <PrivateRoute
              path={MEMBERS_ARTICLE_DETAIL}
              component={ArticleDetail}
              exact
            />
            <Route
              path={PREVIEW_ARTICLE_DETAIL}
              component={ArticleDetail}
              exact
            />
            {/* <Route path={FAQ} component={Faq} exact /> */}
            <Route path={PRIVACY_POLICY} component={PrivacyPolicy} exact />
            <Route path={WHALE_VAULT} component={WhaleVaultScreen} exact />
            <Route path={WHALE_PARTNERS} component={WhalePartners} exact />
            {/* <Route path={WHALE_WHITEPAPER} component={WhaleWhitepaper} exact /> */}
            <Route
              path={TERMS_AND_CONDITIONS}
              component={TermsAndConditions}
              exact
            />
            <Route component={Error404} />
            <Route component={Error403} />
          </Switch>
        </ScrollToTop>
      </Container>
      <Footer />
    </Router>
  </EntryLoader>
);
export default App;

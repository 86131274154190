import { ReactElement } from "react";
import styles from "./ArticleEmbed.module.scss";

interface IArticleEmbed {
  body: string;
}

const ArticleEmbed = ({ body }: IArticleEmbed): ReactElement => (
  <div className={styles.container}>
    <div className={styles.body}>{body}</div>
  </div>
);

export default ArticleEmbed;

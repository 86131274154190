import { API_URL } from "@constants/backend.env";
import { useAxios } from "use-axios-client";
import { API_URL_BACKEND_PARTNER } from "@constants/backend.env";
import { IPartner } from "@interfaces/backoffice/partner";
import { partnersResponse } from "@scripts/PartnersUtils";

interface IPartnerResponse {
  team: IPartner[];
  investors: IPartner[];
  chiefs: IPartner[];
}

const useGetPartners = () =>
  useAxios<IPartnerResponse>({
    baseURL: API_URL,
    url: API_URL_BACKEND_PARTNER,
    params: {
      order: "desc",
      sort: "order",
    },
    transformResponse: [
      (data) => {
        try {
          let partners = JSON.parse(data);
          return partnersResponse(partners);
        } catch (error) {
          console.log(error);
        }
      },
    ],
  });

export default useGetPartners;

import { ReactElement } from "react";
import useGetGlances from "@hooks/backoffice/useGetGlances";
import Title from "@components/Title";
import Loader from "@components/Loader";
import CryptoChart from "./components/CryptoChart";
import styles from "./WhaleAtAGlance.module.scss";

const WhaleAtAGlance = (): ReactElement => {
  const { data, loading } = useGetGlances();

  if (loading || !data) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <Title text={"WHALE AT A GLANCE"} tag="h3" line />
      <div className={styles.gridContainer}>
        <div className={styles.currencyDataContainer}>
          {data?.map(({ value, title }) => (
            <div className={styles.currencyData} key={title}>
              <div className={styles.textContainer}>
                <p>{title}</p>
                <h3>{value}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
      <CryptoChart
        symbols={[["WHALEUSDT|3M", "WHALEUSDT|3M"]]}
        lineColor={"#2D2F41"}
      />
    </div>
  );
};

export default WhaleAtAGlance;

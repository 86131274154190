import { API_URL, API_URL_BACKEND_ARTICLES } from "@constants/backend.env";
import { IArticle } from "@interfaces/backoffice/article";
import { articleResponse } from "@scripts/ArticlesUtils";
import { useAxios } from "use-axios-client";

const useGetArticleById = (id: string) =>
  useAxios<IArticle>({
    baseURL: API_URL,
    url: API_URL_BACKEND_ARTICLES,
    transformResponse: [
      (data) => {
        try {
          // Parse the JSON data to get the list of articles
          const articles = JSON.parse(data);
          
          // Find the article with the matching uuid
          const article = articles.find((item: IArticle) => item.uuid === id);
          
          if (article) {
            return articleResponse(article);
          } else {
            throw new Error("Article not found");
          }
        } catch (error) {
          console.log(error);
        }
      },
    ],
  });

export default useGetArticleById;

import { ReactElement } from "react";
import styles from "./LatestTale.module.scss";
import { TLastestOn } from "types/WhaleLastestOn";
import { generatePath } from "react-router-dom";
import { ARTICLE_DETAIL } from "@constants/routes";

interface ILatestTale {
  post: TLastestOn;
}

const LatestTale = ({ post }: ILatestTale): ReactElement => (
  <div className={styles.container}>
    <div className={styles.imageContainer}>
      <img src={post.image} alt={`article pic`} />
    </div>

    <div className={styles.artInformationContainer}>
      <h4 className={styles.title}>{post.title}</h4>
      <p className={styles.date}>{post.date}</p>
      <div className={styles.readMoreContainer}>
        <a
          href={generatePath(ARTICLE_DETAIL, { id: post.id })}
          rel="noopener noreferrer"
        >
          Read More
        </a>
      </div>
    </div>
  </div>
);

export default LatestTale;

import { ReactElement } from "react";
import Title from "@components/Title";
import LatestOnWhaleCarousel from "./components/LatestOnWhaleCarousel";
import useGetCalendar from "@hooks/backoffice/useGetCalendar";
import styles from "./LatestOnWhale.module.scss";
import { useGetYoutubeLatestOn } from "@hooks/socialMedia/useGetYoutube";
import useGetLatestArticles from "@hooks/backoffice/useGetLatestArticles";

const LatestOnWhale = (): ReactElement => {
  const { data: weeklyCalendar } = useGetCalendar();
  const { data: videoResponse } = useGetYoutubeLatestOn();
  const { data: articlesResponse } = useGetLatestArticles();

  const posts = [
    ...(weeklyCalendar ? [weeklyCalendar] : []),
    ...(videoResponse || []),
    ...(articlesResponse || []),
  ];

  return (
    <div className={styles.container}>
      {posts && (
        <>
          <Title text="THE LATEST ON WHALE" tag="h3" fixedWidth line />
          <LatestOnWhaleCarousel posts={posts} interval={5000} />
        </>
      )}
    </div>
  );
};

export default LatestOnWhale;

import { useAxios } from "use-axios-client";
import {
  API_YOUTUBE_CHANNELID,
  API_YOUTUBE_KEY,
  API_YOUTUBE_PART,
  API_YOUTUBE_PLAYLISTID,
  API_YOUTUBE_URL,
} from "@constants/env";
import { IVideo } from "@interfaces/socialMedia/youtubeResponse";
import { TVideos } from "types/whaleFmPosts";
import {
  youtubeResponseToLastestOn,
  youtubeResponseToVideo,
} from "@scripts/VideosUtils";
import { TLastestOn } from "types/WhaleLastestOn";

const useGetYoutube = () =>
  useAxios<TVideos[]>({
    url: API_YOUTUBE_URL + "/search",
    params: {
      part: API_YOUTUBE_PART,
      channelId: API_YOUTUBE_CHANNELID,
      key: API_YOUTUBE_KEY,
      order: "date",
      maxResults: 12,
    },
    transformResponse: [
      (data) => {
        let videos: TVideos[] = [];

        try {
          let resp = JSON.parse(data);

          resp?.items?.map((video: IVideo) =>
            videos.push(youtubeResponseToVideo(video))
          );
        } catch (error) {
          console.log(error);
        }

        return videos;
      },
    ],
  });

const useGetYoutubePlayList = (maxResults: number) =>
  useAxios<TVideos[]>({
    url: API_YOUTUBE_URL + "/playlistItems",
    params: {
      part: API_YOUTUBE_PART,
      playlistId: API_YOUTUBE_PLAYLISTID,
      key: API_YOUTUBE_KEY,
      order: "date",
      maxResults: maxResults,
    },
    transformResponse: [
      (data) => {
        let videos: TVideos[] = [];

        try {
          let resp = JSON.parse(data);

          resp?.items?.map((video: IVideo) =>
            videos.push(youtubeResponseToVideo(video))
          );
        } catch (error) {
          console.log(error);
        }

        return videos;
      },
    ],
  });

const useGetYoutubeLatestOn = () =>
  useAxios<TLastestOn[]>({
    url: API_YOUTUBE_URL + "/search",
    params: {
      part: API_YOUTUBE_PART,
      channelId: API_YOUTUBE_CHANNELID,
      key: API_YOUTUBE_KEY,
      order: "date",
      maxResults: 2,
    },
    transformResponse: [
      (data) => {
        let videos: TLastestOn[] = [];

        try {
          let resp = JSON.parse(data);

          resp?.items?.map((video: IVideo) =>
            videos.push(youtubeResponseToLastestOn(video))
          );
        } catch (error) {
          console.log(error);
        }

        return videos;
      },
    ],
  });

export { useGetYoutube, useGetYoutubePlayList, useGetYoutubeLatestOn };

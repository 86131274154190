import { IPartner } from "@interfaces/backoffice/partner";
import { ReactElement } from "react";
import styles from "./PartnersSection.module.scss";
import Title from "@components/Title";

interface IPartnersList {
  list: IPartner[];
  title: string;
}

const PartnersSection = ({ list, title }: IPartnersList): ReactElement => (
  <>
    <Title text={title} tag="h3" line />
    <section className={styles.section}>
      <div className={styles.partnerGrid}>
        {list?.map(({ name, imageUrl, uuid }) => (
          <div key={uuid} className={styles.partnerItem}>
            <div className={styles.imageContainer}>
              <img src={imageUrl} alt={`partner ${name}`} />
            </div>
            <p className={styles.imageName}>{name}</p>
          </div>
        ))}
      </div>
    </section>
  </>
);

export default PartnersSection;

import { ReactElement } from "react";
import { useParams } from "react-router-dom";
import Loader from "@components/Loader";
import styles from "./ArticleDetail.module.scss";
import ArticleEmbed from "./components/ArticleEmbed";
import useGetArticleById from "@hooks/backoffice/useGetArticleById";
import Error404 from "@pages/Error404";
import useCheckIsMember from "@hooks/backoffice/useCheckIsMember";
import { EArticleCategories } from "@constants/enums";
import Error403 from "@pages/Error403";
import { PREVIEW_ACCESS } from "@constants/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

const ArticleDetail = (): ReactElement => {
  const { id, preview } = useParams<{ id: string; preview: string }>();
  const { data: article, loading } = useGetArticleById(id);
  const { isMember } = useCheckIsMember();

  if (loading || !article) {
    return <Loader />;
  }

  if (!loading && !article) {
    return <Error404 />;
  }

  const { title, body, publishDate, category, documents } = article;

  if (
    !loading &&
    category === EArticleCategories.MEMBERS &&
    !isMember &&
    (!preview || preview !== PREVIEW_ACCESS)
  ) {
    return <Error403 />;
  }

  return (
    <div className={styles.container}>
      {article && (
        <>
          <h3>{title}</h3>
          <div className={styles.topContainer}>
            {publishDate && (
              <div className={styles.dateContainer}>
                <p>Published {publishDate}</p>
              </div>
            )}
          </div>
          <ArticleEmbed body={body} />
        </>
      )}
      {documents && (
        <div className={styles.documentsContainer}>
          <hr></hr>
          <p className={styles.filesTitle}>Related Files</p>

          <div className={styles.documents}>
            {documents!.map((document) => (
              <a
                key={document.name}
                href={document.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFileAlt}
                  color="black"
                  size="lg"
                  title={document.name}
                />
                <span>{document.name}</span>
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticleDetail;

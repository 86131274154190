import Title from "@components/Title";
import Scroll from "@components/Scroll/Scroll";
import useGetArticles from "@hooks/backoffice/useGetArticles";
import { EArticleCategories } from "@constants/enums";

const MembersArticles = () => {
  const { isLoading, handleGetArticles, articlesToShow, hasMore } =
    useGetArticles(EArticleCategories.MEMBERS);

  const hasArticles = articlesToShow.length > 0 && !isLoading;

  return (
    <div>
      {hasArticles && (
        <>
          <Title text="WHALE MEMBER'S ARTICLES" tag="h3" center line />
          <Scroll
            articlesToShow={articlesToShow}
            isLoading={isLoading}
            handleGetArticles={handleGetArticles}
            hasMore={hasMore}
            isPrivate={true}
          />
        </>
      )}
    </div>
  );
};

export default MembersArticles;

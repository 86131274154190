import { ReactElement } from "react";
import Title from "@components/Title";
import WhaleFmTwitch from "./components/WhaleFmTwitch";
import WeeklyCalendar from "@components/WeeklyCalendar";
import WhaleFmHighlightsCarousel from "./components/WhaleFmHighlightsCarousel";
import DiscoverMoreFm from "./components/DiscoverMoreFm";
import { YOUTUBE } from "@constants/socialMedia.links";
import button_styles from "@components/Button/Button.module.scss";
import styles from "./WhaleFmScreen.module.scss";
import useGetCalendar from "@hooks/backoffice/useGetCalendar";

const WhaleFmScreen = (): ReactElement => {
  const { data: weeklyCalendar } = useGetCalendar();

  return (
    <div className={styles.container}>
      <Title text={"WHALE FM"} tag="h3" line />
      {weeklyCalendar && <WeeklyCalendar image={weeklyCalendar.image} />}
      <Title text={"WATCH EVENTS"} tag="h3" line />
      <WhaleFmTwitch />
      <Title text={"Whale FM Highlights"} tag="h3" fixedWidth line />
      <WhaleFmHighlightsCarousel />
      <DiscoverMoreFm />
      <a
        href={YOUTUBE}
        target="_blank"
        rel="noopener noreferrer"
        className={button_styles.secondary}
      >
        FIND MORE ON OUR YOUTUBE CHANNEL
      </a>
    </div>
  );
};

export default WhaleFmScreen;

import { API_URL } from "@constants/backend.env";
import { useAxios } from "use-axios-client";
import { API_URL_BACKEND_HIGHLIGHTS } from "@constants/backend.env";
import IHotv from "@interfaces/backoffice/hotv";

const useGetHighlight = () =>
  useAxios<IHotv[]>({
    baseURL: API_URL,
    url: API_URL_BACKEND_HIGHLIGHTS,
    params: {
      state: true,
      order: "desc",
      sort: "createdAt",
    },
  });

export default useGetHighlight;

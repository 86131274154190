import Loader from "@components/Loader";
import { IArticle } from "@interfaces/backoffice/article";
import Articles from "@pages/WhaleMembers/components/Articles";
import { ReactElement } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./Scroll.module.scss";

interface IScroll {
  articlesToShow: IArticle[];
  isLoading: boolean;
  handleGetArticles: () => {};
  hasMore: boolean;
  isPrivate: boolean;
}

const Scroll = ({
  articlesToShow,
  isLoading,
  handleGetArticles,
  hasMore,
  isPrivate,
}: IScroll): ReactElement => {
  return (
    <>
      {articlesToShow.length === 0 && isLoading ? (
        <Loader />
      ) : articlesToShow.length > 0 ? (
        <InfiniteScroll
          dataLength={articlesToShow.length}
          next={handleGetArticles}
          hasMore={hasMore}
          loader={<Loader />}
        >
          <Articles articles={articlesToShow!} isPrivate={isPrivate} />
        </InfiniteScroll>
      ) : (
        <div className={styles.noResults}>
          <span>No Articles found</span>
        </div>
      )}
    </>
  );
};

export default Scroll;

import { ReactNode, ReactElement } from "react";
import styles from "./Button.module.scss";

export enum Variant {
  Primary,
  Secondary,
  Link,
}

const stylesMap = {
  [Variant.Primary]: styles.primary,
  [Variant.Secondary]: styles.secondary,
  [Variant.Link]: styles.link,
};

interface ButtonProps {
  children: ReactNode;
  className?: string;
  variant?: Variant;
  [x: string]: unknown;
}

const Button = ({
  children,
  className,
  variant = Variant.Primary,
  ...attributes
}: ButtonProps): ReactElement => (
  <button className={`${className} ${stylesMap[variant]}`} {...attributes}>
    {children}
  </button>
);

export default Button;

import { IPartner } from "@interfaces/backoffice/partner";

export const partnersResponse = (partners: IPartner[]) => {
  const chiefsList = partners.filter((p: IPartner) => p.category === 1);
  const investorsList = partners.filter((p: IPartner) => p.category === 2);
  const teamList = partners.filter((p: IPartner) => p.category === 3);

  return {
    team: teamList,
    investors: investorsList,
    chiefs: chiefsList,
  };
};

import { Redirect, Route, RouteProps } from "react-router-dom";
import { useWeb3 } from "react-dapp-web3";
import { HOME } from "@constants/routes";

type PrivateRouteProps = RouteProps;

const PrivateRoute = ({ ...routeProps }: PrivateRouteProps): JSX.Element => {
  const { isInitialized, isWalletConnected } = useWeb3();

  if (isInitialized && !isWalletConnected) {
    return <Redirect to={HOME} />;
  }

  return <Route {...routeProps} />;
};

export default PrivateRoute;

import { ReactElement } from "react";
import DocumentEmbed from "@components/DocumentEmbed";
import Title from "@components/Title";
import document from "@assets/documents/privacy-policy.pdf";
import styles from "./PrivacyPolicy.module.scss";

const PrivacyPolicy = (): ReactElement => (
  <div className={styles.container}>
    <Title text={"Privacy Policy"} tag="h3" fixedWidth line />
    <DocumentEmbed document={document} />
  </div>
);

export default PrivacyPolicy;

export const API_URL = process.env.REACT_APP_API_BACKEND_URL;
export const API_URL_BACKEND_HIGHLIGHTS = "/highlights.json";
export const API_URL_BACKEND_REPORT = "/vaultreport.json";
export const API_URL_BACKEND_CAROUSEL = "/vaultcarousel.json";
export const API_URL_BACKEND_PARTNER = "/partners.json";
export const API_URL_BACKEND_BENEFITS = "/benefits.json";
export const API_URL_BACKEND_GLANCES = "/glances.json";
export const API_URL_BACKEND_CALENDAR = "/calendars.json";
export const API_URL_BACKEND_FGINDEX = "/feargreedindexlast.json";
export const API_URL_BACKEND_ROLES = "/roles.json";
export const API_URL_BACKEND_ARTICLES = "/articles.json";
export const API_URL_BACKEND_WALLET = "/whalecheck.php";

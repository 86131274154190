import { API_URL, API_URL_BACKEND_BENEFITS } from "@constants/backend.env";
import { useAxios } from "use-axios-client";
import { IBenefit } from "@interfaces/backoffice/benefit";

const useGetBenefits = () =>
  useAxios<IBenefit[]>({
    baseURL: API_URL,
    url: API_URL_BACKEND_BENEFITS,
    params: {
      state: true,
      order: "asc",
      sort: "order",
    },
  });

export default useGetBenefits;

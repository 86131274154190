import { ReactElement } from "react";
import { NavLink } from "react-router-dom";
import {
  WHALE_TALES,
  WHALE_DAO,
  WHALE_VAULT,
  WHALE_MEMBERS,
  WHALE_PARTNERS,
} from "@constants/routes";
import styles from "./Navbar.module.scss";
import useCheckIsMember from "@hooks/backoffice/useCheckIsMember";

const Navbar = (): ReactElement => {
  const { isMember } = useCheckIsMember();

  return (
    <nav className={styles.container}>
      <ul>
        <li>
          <NavLink exact to={WHALE_TALES} activeClassName={styles.activeLink}>
            WHALE TALES
          </NavLink>
        </li>
        <li>
          <NavLink exact to={WHALE_DAO} activeClassName={styles.activeLink}>
          WHALE DAO
          </NavLink>
        </li>
        <li>
          <NavLink to={WHALE_VAULT} activeClassName={styles.activeLink}>
            WHALE VAULT
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to={WHALE_PARTNERS}
            activeClassName={styles.activeLink}
          >
            WHALE PARTNERS
          </NavLink>
        </li>

        {isMember && (
          <li>
            <NavLink
              exact
              to={WHALE_MEMBERS}
              activeClassName={styles.activeLink}
            >
              WHALE MEMBERS
            </NavLink>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;

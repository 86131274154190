import { ReactElement } from "react";
import Title from "@components/Title";
import styles from "./WhaleFmVideo.module.scss";
//import Loader from "../Loader"; Temp commented => cause: client asked for quick change
//import { useGetYoutubePlayList } from "@hooks/socialMedia/useGetYoutube"; Temp commented => cause: client asked for quick change

const data = [
  {
    id: 0,
    title: "$WHALE x BREEZY - XCOPY INTERVIEW",
    videoUrl: "https://www.youtube.com/embed/UFhG8mE-WDE",
  },
];

const WhaleFmVideo = (): ReactElement => {
  // const { data, loading } = useGetYoutubePlayList(1);  Temp commented => cause: client asked for quick change
  const video = data && data[0];

  /* if (loading) {    Temp commented => cause: client asked for quick change
    return <Loader />;
  } */

  return (
    <div className={styles.container}>
      {video && (
        <>
          <Title text={"WHALE FM"} tag="h3" line />
          <iframe
            title={video.title}
            src={video.videoUrl}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
        </>
      )}
    </div>
  );
};

export default WhaleFmVideo;

import { ReactElement } from "react";
import Masonry from "react-masonry-css";
import Loader from "@components/Loader";
import Title from "@components/Title";
import styles from "./DiscoverMoreFm.module.scss";
import { useMediaQuery } from "react-responsive";
import { useGetYoutube } from "@hooks/socialMedia/useGetYoutube";

const DiscoverMoreFm = (): ReactElement => {
  const { data, loading } = useGetYoutube();

  //if it's desktop...
  let masonryBreakpointCols = 3;

  //if it's mobile...
  if (useMediaQuery({ query: "(max-width: 480px)" })) {
    masonryBreakpointCols = 2;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <Title text={"DISCOVER MORE WHALE FM"} tag="h3" center line />
      <Masonry
        breakpointCols={masonryBreakpointCols}
        className={styles.grid}
        columnClassName={styles.gridColumn}
      >
        {data?.map((video) => (
          <div key={video.id} className={styles.videoContainer}>
            <iframe
              title={`whale video ${video.title}`}
              src={video.videoUrl}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
            <h5>{video.title}</h5>
            <p>{video.description}</p>
          </div>
        ))}
      </Masonry>
    </div>
  );
};

export default DiscoverMoreFm;

import { ReactElement } from "react";
import { useWeb3 } from "react-dapp-web3";
import { Link } from "react-router-dom";
import Button from "@components/Button";
import whale_logo from "@assets/images/utils/whale-brand-logo.png";
import { Variant } from "@components/Button/Button";
import Navbar from "@components/Navbar";
import { shortenWalletAddress } from "@scripts/Strings";
import { HOME } from "@constants/routes";
import styles from "./Header.module.scss";

const Header = (): ReactElement => {
  const { isWalletConnected, walletAddress, connect } = useWeb3();

  return (
    <header className={styles.container}>
      <div className={styles.content}>
        <Link to={HOME}>
          <img className={styles.brandLogo} src={whale_logo} alt="whale logo" />
        </Link>

        <div>
          <Navbar />
          <Button onClick={connect} variant={Variant.Primary}>
            {isWalletConnected
              ? shortenWalletAddress(walletAddress)
              : "CONNECT WALLET"}
          </Button>
        </div>
      </div>
    </header>
  );
};

export default Header;

import { ReactElement, HTMLAttributes } from "react";
import styles from "./WhaleFmHighlightsCarousel.module.scss";
import Slider from "react-slick";
//import Loader from "../Loader"; Temp commented => cause: client asked for quick change
//import { useGetYoutubePlayList } from "@hooks/socialMedia/useGetYoutube"; Temp commented => cause: client asked for quick change
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const data = [
  {
    id: 19,
    title: "What is NBA Top Shot?? | $Whale Project Showcase",
    videoUrl: "https://www.youtube.com/embed/pH7qH_qT9WE",
  },
  {
    id: 59,
    title: "New NFT Game Mirandus | $Whale x Gala Games",
    videoUrl: "https://www.youtube.com/embed/zVO61Zoe_-U",
  },
  {
    id: 68,
    title: "NFT Project Showcase w/ WhaleShark & RTFKT (Artifact) Studios",
    videoUrl: "https://www.youtube.com/embed/cFBUFmWz8NY",
  },
  {
    id: 71,
    title: "$WHALE x BREEZY - XCOPY INTERVIEW",
    videoUrl: "https://www.youtube.com/embed/UFhG8mE-WDE",
  },
  {
    id: 314,
    title: "Project Showcase: BIG TIME",
    videoUrl: "https://www.youtube.com/embed/nABA1kxwc6I",
  },
];

const WhaleFmHighlightsCarousel = (): ReactElement => {
  //const { data, loading } = useGetYoutubePlayList(5); Temp commented => cause: client asked for quick change

  function NextArrow(props: HTMLAttributes<HTMLButtonElement>) {
    const { className, onClick } = props;
    return (
      <button
        className={`${className} ${styles.arrow} ${styles.rightArrow}`}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props: HTMLAttributes<HTMLButtonElement>) {
    const { className, onClick } = props;
    return (
      <button
        className={`${className} ${styles.arrow} ${styles.leftArrow}`}
        onClick={onClick}
      />
    );
  }

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: data && data.length < 2 ? 1 : 2,
    slidesToScroll: 2,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  /*  if (loading) {    Temp commented => cause: client asked for quick change
    return <Loader />;
  }*/

  return (
    <div className={styles.container}>
      <Slider {...settings}>
        {data?.map((video) => (
          <div key={video.id} className={styles.videoContainer}>
            <iframe
              title={`whale video ${video.title}`}
              src={video.videoUrl}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
            <h5>{video.title}</h5>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default WhaleFmHighlightsCarousel;
